import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
/// Image
import profile from "../../../assets/images/nuvoton.webp";
import AuthService from "../../components/service/auth.service";
import NodeGroupService from "../../components/service/nodeGroup.service";
import TokenService from "../../components/service/token.service";
import UserService from "../../components/service/user.service";
import { handleError, lsDecrypt, lsEncrypt } from "../../utils/utils";

const Header = ({
  toggle,
  onProfile,
  onNotification,
  onBox,
  onGroup,
  login,
  setOpenSideBar,
  selectUserGroupPermissions,
  reloadTest,
  load,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [userGroupList, setUserGroupList] = useState([]);
  const [selectUserGroup, setSelectUserGroup] = useState("Please select group");
  const [userPermission, setUserPermissions] = selectUserGroupPermissions;
  const [loginStatus, setLoginStatus] = login;
  const [refreshKey, setRefreshKey] = reloadTest;
  const [selectGroupLoad, setSelectGroupLoad] = load;
  const [isRoot, setIsRoot] = useState(false);
  const [belongGroup, setBelongGroup] = useState([]);
  const firstUpdate = useRef(true);

  useEffect(() => {
    // if (JSON.parse(lsDecrypt("userGroupList"))) {
    //   setUserGroupList(JSON.parse(lsDecrypt("userGroupList")));
    // }

    let user = lsDecrypt("user");

    if (JSON.parse(user)) {
      handleGetMe(TokenService.getToken());
      handleUserPermissionAndData();
    }

    if (localStorage.getItem("userGroupName")) {
      setSelectUserGroup(JSON.parse(localStorage.getItem("userGroupName")));
    }
  }, []);

  //used to handle changes in login status.
  useEffect(() => {
    if (loginStatus) {
      setLoginStatus(false);
      handleUserPermissionAndData();
    }
  }, [loginStatus]);

  const handleUserPermissionAndData = () => {
    //root does has permission list
    // if (JSON.parse(localStorage.getItem("userPermission"))) {
    //   setUserPermissions(
    //     JSON.parse(localStorage.getItem("userPermission")).permission
    //   );
    // }

    let permission = lsDecrypt("userPermission");

    let userPermission = JSON.parse(permission);
    if (userPermission) {
      setUserPermissions(userPermission.permission);
    }

    setSelectUserGroup(JSON.parse(localStorage.getItem("userGroupName")));

    let usergroupList = lsDecrypt("userGroupList");

    setUserGroupList(JSON.parse(usergroupList));

    if (JSON.parse(localStorage.getItem("isRoot"))) {
      setIsRoot(true);
    } else {
      setIsRoot(false);
      handleSelectUserGroupPermission();
    }

    setOpenSideBar(false);
  };

  //used to handle changes in user permissions.
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setRefreshKey(refreshKey + 1);
    }
  }, [userPermission]);

  //used to logout the user and clear local storage.
  const handleLogout = () => {
    AuthService.logout(TokenService.getToken())
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          $(".deznav").hide();
          $(".mm-wrapper").hide();
          $("#nav-display").hide();
          handleCloseDropdown();
          TokenService.removeUser();
          localStorage.clear();
          swal("Logout success!", "", "success");
          history.push("/");
        }
      })
      .catch((error) => {
        // console.log(error.response);
        handleError(error, history, () => {
          handleLogout();
        });
      });
  };

  const handleCloseDropdown = () => {
    $("#navbarDropdown")[0].click();
  };

  //used to select a user group and set relevant local storage items.
  const HandleSelectGroup = async (e) => {
    //set a flag start to change group &　token
    localStorage.setItem("change_Token", JSON.stringify(true));
    setSelectGroupLoad(true);
    setTimeout(() => setSelectGroupLoad(false), 4000);

    const handleChangeToken = async () => {
      try {
        const response = await TokenService.changeToken(
          TokenService.getToken(),
          e._id
        );

        console.log("Set Group Success!");
        console.log(response);
        //find the select user group user is manager or not
        await TokenService.updateToken(response.data.token);
        localStorage.removeItem("nodeGroups");
        setSelectUserGroup(e.name);
        localStorage.setItem("userGroup", JSON.stringify(e._id));
        // lsEncrypt("userGroup", JSON.stringify(e._id));
        localStorage.setItem("userGroupName", JSON.stringify(e.name));

        handleGetMe(response.data.token, e._id);

        //once user select group, get the user's group permission
        handleSelectUserGroupPermission();

        onGroup();
        //remove the flag
        setTimeout(() => {
          localStorage.removeItem("change_Token");
        }, 10000);
      } catch (error) {
        console.log(error.response);
        handleError(error, history, () => {
          handleChangeToken();
        });
      }
    };
    await handleChangeToken();
  };

  //   const handleChangeToken = async () => {
  //     try {
  //       const response = await TokenService.changeToken(TokenService.getToken(), e._id)
  //       console.log("Set Group Success!");
  //       console.log(response);
  //       //find the select user group user is manager or not
  //       await TokenService.updateToken(response.data.token);
  //       handleSelectGroupManager(response.data.token, e._id);

  //       //once user select group, get the user's group permission
  //       handleSelectUserGroupPermission();
  //     } catch (error) {
  //       console.log(error.response);
  //       handleError(error, history, () => {
  //         handleChangeToken();
  //       });
  //     }
  //   };

  //   await handleChangeToken();

  //   onGroup();
  // };

  // used to determine if the user is a manager of the selected group.
  const handleGetMe = (token, selectedGroupID) => {
    UserService.getMe(token)
      .then((response) => {
        console.log(response);

        if (selectedGroupID) {
          const targetGroup = response.data.belongsOrgGroup.groups.find(
            (group) => group._id === selectedGroupID
          );
          if (targetGroup) {
            localStorage.setItem(
              "isManager",
              JSON.stringify(targetGroup.isManager)
            );
          } else {
            // console.log("can not find user group");
          }
        } else {
          setBelongGroup(response.data.belongsOrgGroup.groups);
        }
      })
      .catch((error) => {
        // console.log(error.response);
        handleError(error, history);
      });
  };

  useEffect(() => {
    if (belongGroup.length > 0) {
      lsEncrypt("userGroupList", JSON.stringify(belongGroup));
      //reload and set group again
      setUserGroupList(belongGroup);
    }
  }, [belongGroup]);

  // used to get user permissions for the selected group.
  const handleSelectUserGroupPermission = () => {
    let userGroupId = JSON.parse(localStorage.getItem("userGroup"));
    // let userGroupId = JSON.parse(lsDecrypt("userGroup"));
    const handlGroupPermission = () => {
      UserService.getGroupMemberPermissions(
        TokenService.getToken(),
        userGroupId,
        JSON.parse(localStorage.getItem("userID"))
      )
        .then((response) => {
          console.log(response);

          // localStorage.setItem(
          //   "userPermission",
          //   JSON.stringify({ permission: response.data.permissions })
          // );

          lsEncrypt(
            "userPermission",
            JSON.stringify({ permission: response.data.permissions })
          );

          let userPermission = response.data.permissions;
          const handleGetNodeGroup = () => {
            //if user has the permission relate to get node group under the user group
            NodeGroupService.getNodeGroupList(TokenService.getToken())
              .then((response) => {
                // console.log("Get Node Group Under User's Group");
                console.log(response);
                let nodeGroup = response.data.groups;

                //get user group's node group
                const filteredObjects = nodeGroup.filter((item) =>
                  item.userGroups.some((group) => group._id === userGroupId)
                );

                // localStorage.setItem(
                //   "nodeGroups",
                //   JSON.stringify({ nodeGroup: filteredObjects })
                // );

                lsEncrypt(
                  "nodeGroups",
                  JSON.stringify({ nodeGroup: filteredObjects })
                );

                setUserPermissions(userPermission);
                setOpenSideBar(false);

                //since reselect group will need to reload the user's group's node group, we need to redrict to the node selection page again
                if (
                  location.pathname === "/actions" ||
                  location.pathname === "/hostinfo"
                ) {
                  history.push("/manage-nodes");
                }
              })
              .catch((error) => {
                // console.log(error.response);
                handleError(error, history, () => {
                  handleGetNodeGroup();
                });
              });
          };

          handleGetNodeGroup();
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, () => {
            handlGroupPermission();
          });
        });
    };
    handlGroupPermission();
  };

  return (
    <div className="header">
      {/* <div className="test_env">For Frontend & Backend Test</div> */}
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse nav-position  d-sm-inline-flex flex-sm-row ">
            <ul className="navbar-nav header-right">
              <div className="nav-item header-nav-item">
                <div className="input-group search-area d-lg-inline-flex d-none  header-searchbox">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div>
                  <li
                    className="nav-item header-nav-item dropdown head-nav-dropdown"
                    id="header-nav-item1"
                  >
                    <Link
                      className="nav-select-group ai-icon"
                      to="#"
                      role="button"
                      data-toggle="dropdown"
                      onClick={
                        isRoot ? () => console.log("Is Root") : () => onGroup()
                      }
                    >
                      <div className="selectgroup-text">
                        <span> {selectUserGroup}</span>

                        {!isRoot && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="ml-2 bi bi-caret-down-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                          </svg>
                        )}
                      </div>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-right ${
                        toggle === "group" ? "show" : ""
                      }`}
                    >
                      {" "}
                      {userGroupList?.map((item, index) => (
                        <div className="navgroup-dropdown-item" key={index}>
                          <div onClick={() => HandleSelectGroup(item)}>
                            {item.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </li>
                </div>

                <div className="nav-item header-nav-item nav-drophide">
                  <div
                    className="nav-item header-nav-item dropdown"
                    // onClick={() => }
                  >
                    <Link className="nav-link " to="#" href="#" role="button">
                      About
                    </Link>
                  </div>

                  <div className="nav-item header-nav-item dropdown ml-4">
                    <Link className="nav-link" to="/help" role="button">
                      Help
                    </Link>
                  </div>
                </div>
              </div>
              <li
                className="nav-item header-nav-item dropdown notification_dropdown ml-3 nav-drophide"
                id="header-nav-item2"
              >
                <Link
                  className="nav-link  ai-icon"
                  to="#"
                  role="button"
                  data-toggle="dropdown"
                  onClick={() => onNotification()}
                >
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.75 15.8385V13.0463C22.7471 10.8855 21.9385 8.80353 20.4821 7.20735C19.0258 5.61116 17.0264 4.61555 14.875 4.41516V2.625C14.875 2.39294 14.7828 2.17038 14.6187 2.00628C14.4546 1.84219 14.2321 1.75 14 1.75C13.7679 1.75 13.5454 1.84219 13.3813 2.00628C13.2172 2.17038 13.125 2.39294 13.125 2.625V4.41534C10.9736 4.61572 8.97429 5.61131 7.51794 7.20746C6.06159 8.80361 5.25291 10.8855 5.25 13.0463V15.8383C4.26257 16.0412 3.37529 16.5784 2.73774 17.3593C2.10019 18.1401 1.75134 19.1169 1.75 20.125C1.75076 20.821 2.02757 21.4882 2.51969 21.9803C3.01181 22.4724 3.67904 22.7492 4.375 22.75H9.71346C9.91521 23.738 10.452 24.6259 11.2331 25.2636C12.0142 25.9013 12.9916 26.2497 14 26.2497C15.0084 26.2497 15.9858 25.9013 16.7669 25.2636C17.548 24.6259 18.0848 23.738 18.2865 22.75H23.625C24.321 22.7492 24.9882 22.4724 25.4803 21.9803C25.9724 21.4882 26.2492 20.821 26.25 20.125C26.2486 19.117 25.8998 18.1402 25.2622 17.3594C24.6247 16.5786 23.7374 16.0414 22.75 15.8385ZM7 13.0463C7.00232 11.2113 7.73226 9.45223 9.02974 8.15474C10.3272 6.85726 12.0863 6.12732 13.9212 6.125H14.0788C15.9137 6.12732 17.6728 6.85726 18.9703 8.15474C20.2677 9.45223 20.9977 11.2113 21 13.0463V15.75H7V13.0463ZM14 24.5C13.4589 24.4983 12.9316 24.3292 12.4905 24.0159C12.0493 23.7026 11.716 23.2604 11.5363 22.75H16.4637C16.284 23.2604 15.9507 23.7026 15.5095 24.0159C15.0684 24.3292 14.5411 24.4983 14 24.5ZM23.625 21H4.375C4.14298 20.9999 3.9205 20.9076 3.75644 20.7436C3.59237 20.5795 3.50014 20.357 3.5 20.125C3.50076 19.429 3.77757 18.7618 4.26969 18.2697C4.76181 17.7776 5.42904 17.5008 6.125 17.5H21.875C22.571 17.5008 23.2382 17.7776 23.7303 18.2697C24.2224 18.7618 24.4992 19.429 24.5 20.125C24.4999 20.357 24.4076 20.5795 24.2436 20.7436C24.0795 20.9076 23.857 20.9999 23.625 21Z"
                      fill="#555555"
                    />
                  </svg>
                  <span className="badge light text-white bg-red">12</span>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "notification" ? "show" : ""
                  }`}
                >
                  <Link className="all-notification" to="#">
                    See all notifications <i className="ti-arrow-right" />
                  </Link>
                </div>
              </li>

              <li
                className="nav-item header-nav-item dropdown notification_dropdown nav-drophide"
                id="header-nav-item3"
              >
                <Link
                  className="nav-link"
                  to="#"
                  data-toggle="dropdown"
                  onClick={() => onBox()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={35}
                    height={35}
                    className="bi bi-gear"
                    viewBox="-2 -2 20 20"
                    stroke="transparent"
                  >
                    <path
                      d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"
                      fill="#555555"
                    />
                    <path
                      d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"
                      fill="#555555"
                    />
                  </svg>
                  <span className="badge light text-white bg-blue">2</span>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "box" ? "show" : ""
                  }`}
                ></div>
              </li>
              <li
                className="nav-item header-nav-item dropdown header-profile profile-position"
                id="header-nav-item4"
              >
                <Link
                  id="navbarDropdown"
                  to="#"
                  role="button"
                  data-toggle="dropdown"
                  className="nav-link"
                  onClick={() => onProfile()}
                >
                  <div className="header-info">
                    <span>
                      Hello, <strong> Nuvoton</strong>
                    </span>
                  </div>

                  <LazyLoad height={200} offset={1500} once>
                    <img src={profile} width="20" alt="user pic" />
                  </LazyLoad>
                </Link>

                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "profile" ? "show" : ""
                  }`}
                >
                  <Link
                    to="/user-profile"
                    className="dropdown-item ai-icon"
                    onClick={() => handleCloseDropdown()}
                  >
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary profile-dropdown-icon"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>

                  <Link
                    onClick={() => handleLogout()}
                    className="dropdown-item ai-icon"
                    to="#"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger profile-dropdown-icon"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2">Logout </span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
